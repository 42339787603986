import classes from "./Login.module.css";
import { useEffect, useState } from "react";
import useInput from "../../hooks/useInput";
import LetsDoThis from "../../components/LetsDoThis/LetsDoThis";
import { WebAuth } from "auth0-js";
import { useNavigate } from "react-router-dom";
import DefaultInput from "../../components/DefaultInput/DefaultInput";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/Loader/Loader";
import { ClosedEye, Eye } from "../../components/Svgs";

const clientID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;

const isEmail = (value) => value.match(/\S+@\S+\.\S+/);
const isPassword = (value) => value.match(/\d/) && value.length >= 8;

const redirectUrl =
  window.location.origin === "https://signup.floodin.dev" ? "https://portal.floodin.dev" : "https://portal.floodin.app";

const webAuth = new WebAuth({
  domain: domain,
  clientID: clientID,
  responseType: "token",
  redirectUri: redirectUrl,
  audience: "f3-cloud",
});

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const [formSubmited, setFormSubmited] = useState(false);
  const [message, setMessage] = useState({ message: null, type: null });
  const [passwordEye, setPasswordEye] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    loginWithRedirect(); /* redirect to the default Auth0 login page and avoid everything under that */
  }, [loginWithRedirect]);

  const {
    value: emailValue,
    valueChangeHandler: emailChangeHandler,
    inputErrorHandler: emailErrorHandler,
    hasError: emailHasError,
  } = useInput(isEmail);

  const {
    value: passwordValue,
    valueChangeHandler: passwordChangeHandler,
    inputErrorHandler: passwordErrorHandler,
    hasError: passwordHasError,
  } = useInput(isPassword);

  useEffect(() => {
    document.title = "Login - Floodin";

    /* check if there is a user */
    webAuth.checkSession({ redirectUri: window.origin }, (err, authResult) => {
      if (err) {
        console.log(err);
        // no user
        setIsLoading(false);
      } else {
        // isUser
        window.location.href = redirectUrl;
      }
    });
  }, []);

  const loginHandler = (e, email, password) => {
    e.preventDefault();

    /* show inputs Errors */
    emailErrorHandler();
    passwordErrorHandler();

    /* check inputs errors */
    if (!email.match(/\S+@\S+\.\S+/) || password.length < 8 || !password.match(/\d/)) {
      return;
    }

    setFormSubmited(true);

    /* login the user */
    return new Promise((resolve, reject) => {
      webAuth.login({ email, password }, (err, authResult) => {
        if (err) {
          reject(new Error(err.error_description));
          setMessage({ message: err.error_description, type: "error" });
          setFormSubmited(false);
        } else if (!authResult) {
          reject(new Error("login with no authResult!"));
        }
        resolve(authResult);

        setFormSubmited(false);
      });
    });
  };

  return <Loader />;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div
      className={classes.login}
      onClick={() => {
        setMessage({ message: null, type: null });
      }}
    >
      <LetsDoThis />
      <div className={classes.main}>
        <form onSubmit={(e) => loginHandler(e, emailValue, passwordValue)}>
          <div className={classes.top}>
            <img src="/logo.svg" alt="Floodin logo"/>
            <div className={`${classes.message} ${message.message && classes.active} ${classes[message.type]}`}>
              {message.message}
            </div>
          </div>
          <DefaultInput
            type="text"
            value={emailValue}
            onChange={emailChangeHandler}
            placeholder="Email"
            errorMessage=" Please enter a valid Email"
            hasError={emailHasError}
          />
          <div className={`${classes.password} ${passwordHasError ? classes.error : undefined}`}>
            <input
              type={passwordEye ? "text" : "password"}
              placeholder=" "
              value={passwordValue}
              onChange={passwordChangeHandler}
            />
            <span>Password</span>
            <div className={classes.eye} onClick={() => setPasswordEye(!passwordEye)}>
              {passwordEye ? <Eye /> : <ClosedEye />}
            </div>
          </div>
          <span className={`${classes.error} ${passwordHasError && classes["active"]}`}>
            Please enter a valid Password
          </span>
          <div className={classes.forget}>
            <span onClick={() => navigate("/reset")}>Forgot password?</span>
          </div>

          <button className={`btn ${classes.submit} ${formSubmited && classes.active}`}>Log in</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
