export const Cancel = () => (
  <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.0711 41.0711L26.9289 26.9289" stroke="#FF1D4E" strokeWidth="2" strokeLinecap="round" />
    <path d="M26.9289 41.0711L41.0711 26.9289" stroke="#FF1D4E" strokeWidth="2" strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8579 48.1421C27.6684 55.9526 40.3316 55.9526 48.1421 48.1421C55.9526 40.3316 55.9526 27.6684 48.1421 19.8579C40.3316 12.0474 27.6684 12.0474 19.8579 19.8579C12.0474 27.6684 12.0474 40.3316 19.8579 48.1421Z"
      stroke="#FF1D4E"
      strokeWidth="2"
    />
  </svg>
);

export const Check = () => (
  <svg width="76" height="75" viewBox="0 0 76 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38 68.75C55.2589 68.75 69.25 54.7589 69.25 37.5C69.25 20.2411 55.2589 6.25 38 6.25C20.7411 6.25 6.75 20.2411 6.75 37.5C6.75 54.7589 20.7411 68.75 38 68.75Z"
      stroke="#00EE98"
      strokeWidth="3.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M57.1073 21.875L28.932 50.0504L16.125 37.2434" stroke="#00EE98" strokeWidth="3.125" />
  </svg>
);

export const FormCheck = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
      stroke="#9FA2AF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8.5 5L4.54912 8.50806L2.5 6.45894" stroke="#9FA2AF" />
  </svg>
);

export const Lock = () => (
  <svg width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M61.7336 123.467C95.8282 123.467 123.467 95.8282 123.467 61.7336C123.467 27.6391 95.8282 0 61.7336 0C27.6391 0 0 27.6391 0 61.7336C0 95.8282 27.6391 123.467 61.7336 123.467Z"
      fill="#1CA3FF"
    />
    <path
      d="M79.1776 58.0448V46.1818C79.1792 43.925 78.7361 41.6899 77.8736 39.6044C77.0111 37.5189 75.7461 35.6237 74.1508 34.0273C72.5556 32.4309 70.6613 31.1645 68.5764 30.3004C66.4916 29.4364 64.2568 28.9917 62 28.9917C59.7431 28.9917 57.5084 29.4364 55.4235 30.3004C53.3386 31.1645 51.4444 32.4309 49.8492 34.0273C48.2539 35.6237 46.9889 37.5189 46.1264 39.6044C45.2639 41.6899 44.8208 43.925 44.8224 46.1818V58.0448C42.5263 58.1482 40.3584 59.1328 38.7697 60.7939C37.181 62.4549 36.2938 64.6644 36.2927 66.9628V94.9965H87.7073V66.9628C87.7062 64.6644 86.819 62.4549 85.2303 60.7939C83.6416 59.1328 81.4737 58.1482 79.1776 58.0448ZM62 35.0522C64.9506 35.0557 67.7794 36.2294 69.8657 38.3159C71.952 40.4023 73.1256 43.2312 73.1288 46.1818V58.0242H50.8712V46.1818C50.8744 43.2312 52.0479 40.4023 54.1343 38.3159C56.2206 36.2294 59.0494 35.0557 62 35.0522Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M45.0442 121.278C82.8805 119.656 106 100 123.119 55.9127C129.474 85.8717 98.3139 133.454 45.0442 121.278Z"
      fill="white"
    />
  </svg>
);

export const ClosedEye = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 9C20 9 19.6797 9.66735 19 10.5144M12 14C10.392 14 9.04786 13.5878 7.94861 13M12 14C13.608 14 14.9521 13.5878 16.0514 13M12 14V17.5M4 9C4 9 4.35367 9.73682 5.10628 10.6448M7.94861 13L5 16M7.94861 13C6.6892 12.3266 5.75124 11.4228 5.10628 10.6448M16.0514 13L18.5 16M16.0514 13C17.3818 12.2887 18.3535 11.3202 19 10.5144M5.10628 10.6448L2 12M19 10.5144L22 12"
        stroke="#061143"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const Eye = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 12C22 12 19 18 12 18C5 18 2 12 2 12C2 12 5 6 12 6C19 6 22 12 22 12Z"
        stroke="#061143"
        strokeLinecap="round"
      />
      <circle cx="12" cy="12" r="3" stroke="#061143" strokeLinecap="round" />
    </svg>
  );
};

export const PartyPopper = () => (
  <svg width="118" height="116" viewBox="0 0 118 116" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.8947 22.5128C37.5296 22.8779 37.2525 23.3179 37.0211 23.8003L36.9951 23.7742L0.436972 106.128L0.472826 106.164C-0.205142 107.477 0.92915 110.15 3.25314 112.477C5.57714 114.801 8.24989 115.935 9.56345 115.257L9.59605 115.29L91.9495 78.7287L91.9234 78.6994C92.4026 78.4712 92.8426 78.1942 93.2109 77.8226C98.3022 72.7313 90.046 56.2221 74.7754 40.9483C59.4983 25.6745 42.9892 17.4216 37.8947 22.5128Z"
      fill="#FF1D4E"
    />
    <path
      d="M42.3732 37.2179L1.35614 104.056L0.436972 106.126L0.472826 106.162C-0.205142 107.476 0.92915 110.148 3.25314 112.476C4.00934 113.232 4.79487 113.805 5.5641 114.291L55.411 53.5152L42.3732 37.2179Z"
      fill="#EA596E"
    />
    <path
      d="M75.0062 40.6957C90.2279 55.9239 98.6797 72.156 93.8752 76.954C89.074 81.7584 72.8419 73.3099 57.6105 58.0882C42.3856 42.86 33.937 26.6214 38.7382 21.8202C43.5427 17.019 59.7748 25.4676 75.0062 40.6957Z"
      fill="#A0041E"
    />
    <path
      d="M60.5911 42.4642C59.9424 42.989 59.095 43.2628 58.1986 43.165C55.3694 42.8586 52.99 41.8742 51.3244 40.3195C49.5611 38.6735 48.6908 36.4635 48.9287 34.2504C49.3459 30.3651 53.2442 26.7993 59.8903 27.5163C62.475 27.7934 63.6289 26.9622 63.668 26.5646C63.7136 26.1702 62.7651 25.1109 60.1804 24.8305C57.3512 24.5242 54.9718 23.5398 53.3029 21.985C51.5395 20.339 50.666 18.1291 50.9072 15.9159C51.3309 12.0307 55.226 8.4648 61.8655 9.18514C63.7495 9.38723 64.7436 8.99935 65.1641 8.74838C65.4998 8.54303 65.6334 8.34746 65.6465 8.23338C65.6856 7.83899 64.7501 6.77966 62.1589 6.49935C60.3694 6.30378 59.0722 4.70013 59.271 2.90743C59.4633 1.11799 61.0637 -0.176019 62.8596 0.0195481C69.4992 0.733369 72.55 5.04563 72.1295 8.93417C71.7058 12.826 67.8108 16.3853 61.1647 15.6715C59.2808 15.4661 58.2964 15.8573 57.8727 16.1082C57.537 16.3103 57.4001 16.5091 57.387 16.62C57.3446 17.0176 58.2866 18.0737 60.8779 18.354C67.5174 19.0711 70.5683 23.3801 70.1478 27.2686C69.7273 31.1539 65.8323 34.7197 59.1895 33.9994C57.3055 33.7973 56.3147 34.1884 55.8909 34.4362C55.5519 34.6448 55.4216 34.8403 55.4085 34.9512C55.3661 35.3456 56.3081 36.4049 58.8961 36.6852C60.6823 36.8808 61.9828 38.4877 61.784 40.2771C61.6928 41.1702 61.2397 41.9427 60.5911 42.4642Z"
      fill="#1CA3FF"
    />
    <path
      d="M99.9377 72.6077C106.369 70.7922 110.805 73.6605 111.861 77.4252C112.917 81.1866 110.629 85.9486 104.201 87.7576C101.691 88.4617 100.938 89.6612 101.039 90.0425C101.15 90.4271 102.425 91.0595 104.928 90.3522C111.356 88.5432 115.792 91.4115 116.848 95.1729C117.91 98.9376 115.616 103.693 109.185 105.505C106.678 106.209 105.922 107.412 106.033 107.794C106.14 108.175 107.412 108.807 109.918 108.103C111.646 107.618 113.451 108.625 113.937 110.355C114.419 112.089 113.412 113.889 111.678 114.378C105.254 116.187 100.814 113.325 99.7519 109.557C98.6958 105.795 100.987 101.04 107.421 99.2277C109.931 98.5204 110.684 97.3242 110.573 96.9395C110.469 96.5582 109.198 95.9226 106.695 96.6266C100.26 98.4389 95.8275 95.5771 94.7682 91.8059C93.7088 88.0445 96.0002 83.2889 102.431 81.4734C104.934 80.7726 105.687 79.5666 105.583 79.1885C105.472 78.8039 104.204 78.1716 101.698 78.8756C99.9637 79.3645 98.1678 78.3541 97.6789 76.6233C97.1932 74.8958 98.2036 73.0966 99.9377 72.6077Z"
      fill="#00EE98"
    />
    <path
      d="M74.9702 63.8172C74.0119 63.8172 73.0667 63.3967 72.4213 62.5949C71.2968 61.1868 71.5282 59.1366 72.9298 58.0121C73.6403 57.4417 90.5895 44.1301 114.543 47.5558C116.326 47.81 117.565 49.4593 117.311 51.2422C117.056 53.0219 115.42 54.2735 113.621 54.0062C92.4572 51.001 77.1573 62.9828 77.0073 63.1034C76.4011 63.5858 75.684 63.8172 74.9702 63.8172Z"
      fill="#1CA3FF"
    />
    <path
      d="M18.754 50.2574C18.4444 50.2574 18.1282 50.2117 17.8153 50.1205C16.091 49.6022 15.1132 47.7867 15.6315 46.0625C19.3244 33.7645 22.6719 14.1393 18.5584 9.02197C18.0989 8.44179 17.4046 7.87138 15.814 7.99198C12.7566 8.22666 13.0467 14.6771 13.05 14.7423C13.1869 16.5383 11.8374 18.1028 10.0447 18.2365C8.22271 18.3473 6.68424 17.0239 6.5506 15.228C6.21488 10.7332 7.61319 2.07607 15.3251 1.49262C18.7671 1.23187 21.6256 2.42809 23.6432 4.93787C31.3714 14.5565 23.5259 42.4412 21.8766 47.9366C21.4528 49.348 20.1556 50.2574 18.754 50.2574Z"
      fill="#00EE98"
    />
    <path
      d="M83.1148 33.9612C85.815 33.9612 88.004 31.7723 88.004 29.072C88.004 26.3718 85.815 24.1829 83.1148 24.1829C80.4145 24.1829 78.2256 26.3718 78.2256 29.072C78.2256 31.7723 80.4145 33.9612 83.1148 33.9612Z"
      fill="#00EE98"
    />
    <path
      d="M6.51892 63.2944C10.1192 63.2944 13.0378 60.3758 13.0378 56.7755C13.0378 53.1752 10.1192 50.2566 6.51892 50.2566C2.91862 50.2566 0 53.1752 0 56.7755C0 60.3758 2.91862 63.2944 6.51892 63.2944Z"
      fill="#1CA3FF"
    />
    <path
      d="M105.932 66.5536C108.632 66.5536 110.821 64.3647 110.821 61.6645C110.821 58.9642 108.632 56.7753 105.932 56.7753C103.232 56.7753 101.043 58.9642 101.043 61.6645C101.043 64.3647 103.232 66.5536 105.932 66.5536Z"
      fill="#00EE98"
    />
    <path
      d="M76.5972 105.668C79.2974 105.668 81.4864 103.479 81.4864 100.779C81.4864 98.0789 79.2974 95.8899 76.5972 95.8899C73.897 95.8899 71.708 98.0789 71.708 100.779C71.708 103.479 73.897 105.668 76.5972 105.668Z"
      fill="#00EE98"
    />
    <path
      d="M91.266 17.6633C94.8663 17.6633 97.7849 14.7447 97.7849 11.1444C97.7849 7.54411 94.8663 4.62549 91.266 4.62549C87.6657 4.62549 84.7471 7.54411 84.7471 11.1444C84.7471 14.7447 87.6657 17.6633 91.266 17.6633Z"
      fill="#F1F621"
    />
    <path
      d="M105.932 30.7012C108.632 30.7012 110.821 28.5123 110.821 25.812C110.821 23.1118 108.632 20.9229 105.932 20.9229C103.232 20.9229 101.043 23.1118 101.043 25.812C101.043 28.5123 103.232 30.7012 105.932 30.7012Z"
      fill="#F1F621"
    />
    <path
      d="M96.1524 43.7387C98.8526 43.7387 101.042 41.5497 101.042 38.8495C101.042 36.1493 98.8526 33.9603 96.1524 33.9603C93.4521 33.9603 91.2632 36.1493 91.2632 38.8495C91.2632 41.5497 93.4521 43.7387 96.1524 43.7387Z"
      fill="#F1F621"
    />
    <path
      d="M24.4448 79.5922C27.1451 79.5922 29.334 77.4033 29.334 74.703C29.334 72.0028 27.1451 69.8138 24.4448 69.8138C21.7446 69.8138 19.5557 72.0028 19.5557 74.703C19.5557 77.4033 21.7446 79.5922 24.4448 79.5922Z"
      fill="#F1F621"
    />
  </svg>
);
