import { useEffect } from "react";
import LetsDoThis from "../../components/LetsDoThis/LetsDoThis";
import { Check } from "../../components/Svgs";
import classes from "./Verified.module.css";
const Verified = () => {
  useEffect(() => {
    document.title = "Email verified - Floodin";
  }, []);

  return (
    <div className={classes.verified}>
      <img src="/logo.svg" className={classes.logo} alt="Floodin logo" />
      <LetsDoThis />

      <div className={classes.main}>
        <div className={classes.text}>
          <Check />
          <h2>email successfully verified!</h2>
        </div>
      </div>
    </div>
  );
};

export default Verified;
