import { useState } from "react";

const useInput = (validateValue) => {
  const [enteredValue, setEnteredValue] = useState("");
  const [hasError, setHasError] = useState(false);
  const [formSubmited, setFormSubmited] = useState(false);
  
  const valueChangeHandler = (e) => {
    formSubmited && setHasError(!validateValue(e.target.value));
    setEnteredValue(e.target.value);
  };

  const inputErrorHandler = () => {
    setHasError(!validateValue(enteredValue));
    setFormSubmited(true);
  };
  return {
    value: enteredValue,
    valueChangeHandler,
    inputErrorHandler,
    hasError,
  };
};

export default useInput;
