import { TailSpin } from "react-loader-spinner";
import classes from "./Loader.module.css";
const Loader = () => {
  return (
    <div className={classes.loader}>
      <TailSpin color="#1ca3ff" height={120} width={120} />
      <img src="/powered.svg" alt="Porwered by Floodin"/>
    </div>
  );
};

export default Loader;
