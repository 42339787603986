import classes from "./DefaultInput.module.css";
const DefaultInput = ({
  type,
  value,
  onChange,
  placeholder,
  errorMessage,
  hasError,
}) => {
  return (
    <>
      <div className={`${classes.input} ${hasError ? classes.error : undefined}`}>
        <input
          type={type}
          placeholder=" "
          value={value}
          onChange={onChange}
        />
        <span>{placeholder}</span>
      </div>
      <span className={`${classes.error} ${hasError && classes["active"]}`}>
        {errorMessage}
      </span>
    </>
  );
};

export default DefaultInput;
