import classes from "./LetsDoThis.module.css";
const LetsDoThis = () => {
  return (
    <div className={classes.image}>
      <h2>Let’s DO <span>THIS</span></h2>
    </div>
  );
};

export default LetsDoThis;
