import { Route, Routes } from "react-router-dom";
import Failed from "./pages/Failed/Failed";
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Verified from "./pages/Verified/Verified";

const App = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/reset" element={<ResetPassword />} />
      <Route path="/verified" element={<Verified />} />
      <Route path="/failed" element={<Failed />} />
     {/*  <Route path="/invite" element={<Invite />} /> */}
    </Routes>
  );
};

export default App;
