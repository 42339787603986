import LetsDoThis from "../../components/LetsDoThis/LetsDoThis";
import classes from "./Failed.module.css";
import { Cancel } from "../../components/Svgs";
import { Link } from "react-router-dom";

const Failed = () => {
  return (
    <div className={classes.failed}>
      <img src="/logo.svg" className={classes.logo} alt="Floodin logo" />
      <LetsDoThis />

      <div className={classes.main}>
        <div className={classes.text}>
          <Cancel />
          <h2>
            Oh snap!
            <br />
            verification failed!
          </h2>
          <Link to="/">Return to signup</Link>
        </div>
      </div>
    </div>
  );
};

export default Failed;
