import { WebAuth } from "auth0-js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import DefaultInput from "../../components/DefaultInput/DefaultInput";
import LetsDoThis from "../../components/LetsDoThis/LetsDoThis";
import { Check } from "../../components/Svgs";
import useInput from "../../hooks/useInput";
import classes from "./ResetPassword.module.css";

const clientID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;

const isEmail = (value) => value.match(/\S+@\S+\.\S+/);
const ResetPassword = () => {
  const [message, setMessage] = useState({ message: null, type: null });
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [buttonStatus, setButtonStatus] = useState("active");
  const navigate = useNavigate();

  const redirectUrl =
    window.location.origin === "https://signup.floodin.dev"
      ? "https://portal.floodin.dev"
      : "https://portal.floodin.app";

  const webAuth = new WebAuth({
    domain: domain,
    clientID: clientID,
    responseType: "code",
    redirectUri: redirectUrl,
    audience: "f3-cloud",
  });

  const {
    value: emailValue,
    valueChangeHandler: emailChangeHandler,
    inputErrorHandler: emailErrorHandler,
    hasError: emailHasError,
  } = useInput(isEmail);

  const resetPasswordHandler = (e, email) => {
    e.preventDefault();
    emailErrorHandler();
    if (emailValue.match(/\S+@\S+\.\S+/)) {
      setButtonStatus("submitting");

      webAuth.changePassword(
        {
          connection: "Username-Password-Authentication",
          email,
        },
        function (err, resp) {
          if (err) {
            setButtonStatus("active");
          } else {
            setMessage({
              message: `We've just sent you an email to reset your password.`,
              type: "success",
            });
            setResetEmailSent(true);
            setButtonStatus("active");
          }
        }
      );
    }
  };

  return (
    <div className={classes.reset}>
      <img className={classes.close} src="/close.svg" alt="close icon" onClick={() => navigate("/")} />
      <LetsDoThis />

      <div className={`${classes.main} ${resetEmailSent && classes.reset}`}>
        {/* Email has sent UI */}
        {resetEmailSent && (
          <div className={classes["reset-email"]}>
            <Check />
            <h2>CHECK YOUR EMAIL!</h2>
            <p>We have sent you a link that you can use to reset your password</p>
            <p>If you can't find the Email, please also check your Spam folder.</p>
          </div>
        )}

        {/* Email reseting UI */}
        {!resetEmailSent && (
          <form onSubmit={(e) => resetPasswordHandler(e, emailValue)}>
            <div className={classes.top}>
              <img src="/logo.svg" alt="Floodin logo" />
            </div>
            <div className={classes["top-text"]}>
              <h2>No password? No problem!</h2>
              <p>
                Please enter your Email here. If it is associated to a Floodin account, we will send you an Email to
                reset your password.
              </p>
              <div className={`${classes.message} ${message.message && classes.active} ${classes[message.type]}`}>
                {message.message}
              </div>
            </div>
            <DefaultInput
              type="text"
              value={emailValue}
              onChange={emailChangeHandler}
              placeholder="Email"
              errorMessage="Please enter a valid Email"
              hasError={emailHasError}
            />
            <Button text={"Send Email"} status={buttonStatus} />
            <span onClick={() => navigate("/")}>Cancel</span>
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
