import classes from "./Button.module.css";
import { Oval } from "react-loader-spinner";

const Button = ({ text, status, onClick }) => {
  return (
    <button className={`${classes.button} ${classes[status]}`} onClick={onClick}>
      {status === "submitting" ? (
        <Oval color="#1891e2" secondaryColor="white" strokeWidth={4} strokeWidthSecondary={6} height={18} />
      ) : (
        text
      )}
    </button>
  );
};

export default Button;
